/* React */
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/* Pages */
const HomePage = lazy(() => import("./views/Home/HomePage"));
const SearchListPage = lazy(() => import("./views/SearchList/SearchListPage"));
const BookNowPage = lazy(() => import("./views/BookNowPage/BookNowPage"));
const PostPayment = lazy(() => import("./views/PostPayment/PostPayment"));
const ActivityDetailsPage = lazy(() => import("./views/ActivityDetailsPage/ActivityDetailsPage"));

const routes = [
  { path: "/", element: HomePage },
  { path: "/searchList", element: SearchListPage },
  { path: "/bookNow", element: BookNowPage },
  { path: "/postPayment", element: PostPayment },
  { path: "/activityDetails", element: ActivityDetailsPage },
];

function App() {
  return (
    <Router>
      <div
        className="App"
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {routes.map((route, i) => (
              <Route path={route.path} key={i} element={<route.element />} />
            ))}
            {/* Redirect all unmapped routes to home */}
            <Route path="*" element={<HomePage />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
